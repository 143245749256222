import React from "react";
import style from './index.module.less';
//props
interface UserAssetProps {
    //总资产
    total:string,
    //可用资产
    usable:string,
    //冻结资产
    locked:string
}
const UserAsset: React.FC<UserAssetProps> = ({total,usable,locked}) => {
    return (
        <>
            <div className={style.container}>
                <div className={style.item}>
                    <div className={style.lable}>总资产（卡时）</div>
                    <div className={style.sum_value}>{total}</div>
                </div>
                <div className={style.item}>
                    <div className={style.lable}>可用资产</div>
                    <div className={style.value}>{usable}</div>
                </div>
                <div className={style.item}>
                    <div className={style.lable}>已冻结资产</div>
                    <div className={style.value}>{locked}</div>
                </div>
            </div>
        </>
    );
}

export default UserAsset;