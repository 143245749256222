import React from "react";
import style from './index.module.less';
const IcpHr:React.FC = () =>{
    return(
        <>
            <div className={style.hr_top}></div>
            <div className={style.hr_bottom}></div>
        </>
    )
}

export default IcpHr;