import React, { useEffect, useState } from "react";
import style from './index.module.less';
import { invite_shareprofit } from '@/api/assest/index';
import { Shareprofit } from '@/api/assest/types/assest';
import { apiResponseList } from '@/api/apiType';
import IcpNull from "@/components/icp-null";
const Seniority: React.FC = () => {
    const [list, setList] = useState<Array<Shareprofit>>();
    const [getShare, setGetShare] = useState<boolean>(false);//是否完成查询分享

    useEffect(() => {
        invite_shareprofit({ current: 1, size: 9999 }).then((res: apiResponseList<Shareprofit>) => {
            if (res.code === 2000 && res.data.records.length > 0) {
                setList(res.data.records)
            }
            setGetShare(true);
        })
    }, [])
    return (
        <>
            <div className={style.container}>
                <div className={style.head}>
                    <span >好友名称</span>
                    <span>我的累积收益(卡时)</span>
                </div>
                {
                    getShare ?
                        list && list.length > 0 ?
                            <ShareList list={list}></ShareList>
                            : 
                            <IcpNull message={'您还没有已邀请成功的好友，赶快去邀请吧！'}></IcpNull>
                    : null
                }
            </div>
        </>
    )
}

interface ShareListProps {
    list: Array<Shareprofit>
}
const ShareList: React.FC<ShareListProps> = ({ list }) => {
    const formatDateTime = (timestamp: number): string => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // 注意getMonth()返回的是0-11
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedMonth = month < 10 ? '0' + month : month;
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedHours = hours < 10 ? '0' + hours : hours;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        return `${year}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}`;
    }

    const getName = (item: Shareprofit): string => {
        if (item) {
            if (item.nickname === '') {
                return `tai${item.userId}`;
            } else {
                return item.nickname;
            }
        } else {
            return ''
        }
    }
    return (
        <>
            {
                list && list.map((item) => (
                    <div className={style.item} key={item.nickname}>
                        <div className={style.user}>
                            <img className={style.avatar} src={item?.icon ?? ''} alt="太一灵境" draggable="false" />
                            <div>
                                <div>{getName(item)}</div>
                                <div className={style.time}>{formatDateTime(item.userRegDate)}</div>
                            </div>
                        </div>
                        <div className={style.value}>
                            {item?.tokenAmount ?? ''}
                        </div>
                    </div>
                ))
            }
        </>
    )
}

export default Seniority;