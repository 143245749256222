import React, { useEffect, useState } from "react";
import style from './index.module.less';
import Prompt from "./components/prompt";
import { assest_details, asset_sum } from '@/api/assest/index';
import { apiResponseList, apiResponse } from "@/api/apiType";
import { Income } from "@/api/assest/types/assest";
const Billing: React.FC = () => {
    const [list, setList] = useState<Income[]>();
    const [sum, setSum] = useState<string>();
    useEffect(() => {
        assest_details({
            current: 1,
            size: 9999
        }).then((res: apiResponseList<Income>) => {
            if (res.code === 2000 && res.data.records.length > 0) {
                setList(res.data.records)
            }
        })
        asset_sum().then((res: apiResponse<string>) => {
            if(res.code === 2000){
                setSum(res.data)
            }
        })
    }, [])
    const formatDateTime = (timestamp: number): string => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // 注意getMonth()返回的是0-11
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedMonth = month < 10 ? '0' + month : month;
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedHours = hours < 10 ? '0' + hours : hours;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
        return `${year}-${formattedMonth}-${formattedDay} ${formattedHours}:${formattedMinutes}`;
    }
    return (
        <>
            <div className={style.root}>
                <div className={style.title}>收益明细</div>
                <div className={style.container}>
                    {
                        list && list.map((item: Income) => (
                            <div className={style.item} key={item.id}>
                                <div>
                                    <div className={style.name}>{item?.description ?? ''}
                                        {item && item.freeze == 1 && <Prompt info={'当对方完成10卡时收益后解冻'}></Prompt>}
                                    </div>
                                    <div className={style.time}>{formatDateTime(item.assetDate)}</div>
                                </div>
                                <div className={style.value}>+ {item?.tokenAmount ?? '0'}</div>
                            </div>
                        ))
                    }
                </div>
                <div className={style.footer}>
                    累计收益{sum}卡时
                </div>
            </div>
        </>
    )
}

export default Billing;