import React, { useEffect, useState } from "react";
import './App.css';
import { RouterProvider } from "react-router-dom";
import { user_info } from '@/api/user/index'
import { UserInfoT } from "@/api/user/types/user";
import { apiResponse } from "@/api/apiType";
import { asset_overview } from '@/api/assest/index'
import { Overview } from "@/api/assest/types/assest";
import router from './router';
import { Meta } from 'taiyiweb'; //导入 taiyiweb sdk 
import style from './app.module.less';
import useStore from "@/store/store";
const App: React.FC = () => {
  const [start, setStart] = useState<boolean>(false); //是否是launcher 
  const setoverview = useStore(state => state.setOverview);
  const setuser = useStore(state => state.setUserInfo)

  const [init, setInit] = useState<boolean>(false);//是否完成初始化
  useEffect(() => {
      let metaClass: any = new Meta(); // 创建 meta 类
      if(metaClass.isPcLauncher()){
        setStart(true);
        let token = metaClass.getClientEnv();
        sessionStorage.setItem('token', token);
        let env= metaClass.getClientEnv();
        sessionStorage.setItem('env', env);
        getInitData();
        setInit(true);
      }else{
        setStart(false);
      }
      // console.log("metaClass", metaClass)
      //是否在客户端内
      // if (metaClass.isPcLauncher()) {
      //   console.log("isPcLauncher")
      //   setStart(true);
      //   console.log("isPcLauncher111")
      //   //设置token
      //   metaClass.asynGetToken().then((ton: string) => {
      //     console.log("asynGetToken")
      //     sessionStorage.setItem('token', ton);
      //     //设置env
      //     metaClass.asynGetClientEnv().then((ev: string) => {
      //       sessionStorage.setItem('env', ev);
      //       console.log("asynGetClientEnv")
      //       //获取初始化数据
      //       getInitData();
      //       setInit(true);
      //       console.log("setInit(true);")
      //     })
      //   })
      // } else {
      //   setStart(false);
    
      // }
 
  }, [])

  /**
   * 获取初始化数据
   */
  const getInitData = () => {
    console.log("getInitData")
    //获取用户信息
    user_info().then((res: apiResponse<UserInfoT>) => {
      if (res.code === 2000) {
        setuser(res.data);
      }
    })
    //获取资产
    asset_overview().then((res: apiResponse<Overview>) => {
      if (res.code === 2000) {
        setoverview(res.data);
      }
    })
  }

  return (
    <>
      {start ?
        init ? <RouterProvider router={router} /> : <h1>初始化失败</h1>
        :
        <div className={style.error_title}>
          <h1>《星际算力资产》启动失败，请前往太一灵境客户端启动</h1>
          <h2>太一灵境客户端下载链接<a href="https://www.taiyi.cn/">taiyi.cn</a></h2>
        </div>
      }
    </>
  );
}



export default App;
