import React from "react";
import style from './index.module.less';

interface MoreProps {
    title?:string,
    children?:React.ReactNode
}

const More: React.FC <MoreProps> = ({title,children}) => {
    return (
        <>
            <div className={style.container}>
                <div className={style.title}>{title}</div>
                <div>
                    {children}
                </div>
            </div>
        </>
    )
}
export default More;