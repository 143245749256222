import React, { useState } from "react";
import style from './index.module.less';
interface PromptProps {
    info:string
}
const Prompt: React.FC<PromptProps> = ({info}) => {
    const [isToast, setIsToast] = useState<boolean>(false)
    return (
        <>
            <div className={style.container}>
            <div className={isToast?`${style.toast} ${style.show}`:`${style.toast}  ${style.hide}`}>{info}</div> 
                <img src="https://card-bag.oss-cn-beijing.aliyuncs.com/card-bag/20240126/xJ9kujMk60yk8hUA.svg" alt="太一灵境"
                    draggable="false" className={style.icon}
                    onMouseOver={
                        () => setIsToast(true)
                    }
                    onMouseOut={
                        () => setIsToast(false)
                    }
                />
            </div>
        </>
    )
}

export default Prompt;