import React, { useEffect, useState } from "react";
import style from './index.module.less';
import {invite_overview} from '@/api/assest/index';
import {Invite} from '@/api/assest/types/assest'
import {apiResponse} from '@/api/apiType';
const Signage:React.FC = () =>{
    const [share,setShare] = useState<Invite>();
    
    useEffect(()=>{
        invite_overview().then((res:apiResponse<Invite>)=>{
            if(res.code===2000){
                setShare(res.data)
            }
        })
    },[])
    
    return(
        <>
            <div className={style.container}> 
                <div>
                    <div className={style.lable}>已邀请(人)</div>
                    <div className={style.value}>{share?.invitePersonCount??'0'}</div>
                </div>
                <div>
                    <div className={style.lable}>当前邀请奖励(卡时/人)</div>
                    <div className={style.value}>{share?.inviteAmount??'0'}</div>
                </div>
                <div>
                    <div className={style.lable}>每日分成奖励</div>
                    <div className={style.value}>{share?.shareProfitRate??'0'}%</div>
                </div>
                <div>
                    <div className={style.lable}>邀请累计收益(卡时)</div>
                    <div className={style.value}>{share?.inviteAllAmount??'0'}</div>
                </div>
            </div>
        </>
    )
}

export default Signage;