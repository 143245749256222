import React, {  } from "react";
import style from './index.module.less';
import IcpButton,{BtnType} from "@/components/icp-button";
import useStore from "@/store/store";
import { UserInfoT } from "@/api/user/types/user";

interface UserInfoProps {
    //解除质押
    onUnbundle:()=>void
}

const UserInfo:React.FC <UserInfoProps> = ({onUnbundle}) =>{
    const user = useStore(state => state.userInfo);
    const overview = useStore(state => state.overview);
    const getAvatar = (user:UserInfoT|null) :string => {
        if(user){
            if(user.icon && user.icon!==''){
                return user.icon
            }else{
                return 'https://card-bag.oss-cn-beijing.aliyuncs.com/card-bag/20240127/JLBzMivFzEhdejOQ.png'
            }
        }else{
            return 'https://card-bag.oss-cn-beijing.aliyuncs.com/card-bag/20240127/JLBzMivFzEhdejOQ.png'
        }
    }
    const getName = (user:UserInfoT|null) :string =>{
        if(user){
            if(user.nickname!==''){
                return user.nickname;
            }else{
                return `tai${user.id}`;
            }
        }else{
            return '';
        }
    }
    return(
        <>
            <div className={style.container}>
                <div className={style.info}>
                    <img className={style.avatar} src={getAvatar(user)} alt="太一灵境" draggable="false"/>
                    <div className={style.user}>
                        <div className={style.name}>{getName(user)}</div>
                        <div className={style.value}>
                            <span className={style.lable}>当前权益</span>
                            <span className={style.level}>星际算力节点 {overview?.powerLevel ?? ''}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <IcpButton type={BtnType.Collateral} disable={overview && overview.powerLevel===1 ? true:false} 
                    onClick={onUnbundle}
                    >解押</IcpButton>
                </div>
            </div>
        </>
    )
}

export default UserInfo;