import React from "react";
import style from './index.module.less';
//props
interface InvitationsProps {
    //邀请人数
    quorum?:number,
    //邀请获取的收益
    amount?:string
}
const Invitations :React.FC<InvitationsProps> = ({quorum,amount}) =>{
    return (
        <>
            <div className={style.container}>
                <div className={style.people}>
                    <span className={style.people_lable}>已邀请</span>
                    <span className={style.quorum}>{quorum}<span className={style.unit}>人</span></span>
                    
                </div>
                <div className={style.split }></div>
                <div className={style.attend}>
                    <div className={style.attend_lable}>邀请累计收益（卡时）</div>
                    <div className={style.amount}>{amount}</div>
                </div>
            </div>
        </>
    )
}

export default Invitations;