import style from "./index.module.less";
import React from "react";
import UserInfo from './components/userInfo';
import UserAsset from "./components/userAsset";
import Invitations from "./components/invitations";
import More from "./components/more";
import IcpButton, { BtnType } from "@/components/icp-button";
import IcpHr from "@/components/icp-hr";
import Statement from "./components/statement";
import { NavigateFunction, useNavigate } from "react-router-dom";
import useStore from "@/store/store";

interface MyJump {  
    type: number;  
}  
const HomePage: React.FC = () => {
    const navigate: NavigateFunction = useNavigate();
    const overview = useStore(state => state.overview);

    const jump = (type:number) =>{
        const state: MyJump = {type} 
        navigate('/upgrade',{state})
    }
    return (
        <>
            <div className={style.root}>
                <UserInfo onClick={() => navigate('/billing')}  ></UserInfo>
                <div className={style.assest}>
                    <UserAsset locked={overview?.asset.locked ?? '0'} total={overview?.asset.total ?? '0'} usable={overview?.asset.usable ?? '0'}></UserAsset>
                    <Invitations quorum={overview?.invitePersonCount ?? 0} amount={overview?.inviteAllAmount ?? '0'}></Invitations>
                </div>
                <div className={style.title1}>
                    获取更多收益
                </div>
                <div className={style.more}>
                    <More title={'质押卡时升级星际算力节点，可获得多项权益'}>
                        <IcpButton type={BtnType.Pledges} onClick={() => jump(0) } >质押升级</IcpButton>
                    </More>
                    <IcpHr></IcpHr>
                    <More title={'最高可获得所有受邀好友每日收益的35%奖励'}>
                        <IcpButton type={BtnType.Attend} onClick={() => jump(1)} >邀请好友</IcpButton>
                    </More>
                    <IcpHr></IcpHr>
                    <More title={'开通太一灵境VIP，享总收益5%额外奖励'}>
                        {
                            overview && overview.vip == 1 ?
                                <IcpButton type={BtnType.Vip} className={style.myvip} >VIP加速中</IcpButton>
                                : <IcpButton type={BtnType.Vip} >开通VIP</IcpButton>
                        }

                    </More>
                </div>
                <div className={style.statement}>
                    <Statement list={overview?.last7dayIncome ?? []}></Statement>
                </div>
                <div className={style.base_incentive}>
                    <span className={style.title2}>基础奖励</span>
                    <span className={style.vice}>当日奖励总额于次日凌晨统计完毕并发放。（算力任务即将上线）</span>
                </div>
                <img src="https://card-bag.oss-cn-beijing.aliyuncs.com/card-bag/20240124/AiRkzyOPqJQwdVrc.png" alt="基础奖励规则" className={style.incentive_img}
                    draggable="false"
                />
                <div className={style.other}>
                    <div className={`${style.title}`}>卡时消费</div>
                    <div className={style.item}>可在积分商城（即将上线）兑换各类AI服务、商品和代金券（1卡时约等于1元购买力）</div>
                </div>
            </div>
        </>
    )
}
export default HomePage;