import React, { Dispatch, SetStateAction } from "react";
import IcpDialog from "../icp-dialog";
import style from './index.module.less';
import IcpHr from "../icp-hr";
export enum ModalType {
    //提示
    Prompt = "Prompt",
    //询问
    Inquire = "Inquire"
}

export interface IcpModalProps {
    //弹框类型
    type: ModalType,
    //确定按钮文本 询问类型有效
    identifyLable?: string,
    //确定按钮点击回调 询问类型有效
    identifyCallBack?: () => void,
    //取消按钮文本 询问类型有效
    cancelLable?: string,
    //取消按钮点击回调
    cancelCallBack?: () => void,
    //信息
    message: string,
    //是否显示弹框
    is: boolean,
    setIs: Dispatch<SetStateAction<boolean>>
}

const IcpModal: React.FC<IcpModalProps> = ({ type, identifyLable, identifyCallBack, cancelLable, cancelCallBack, message, is, setIs }) => {
    return (
        <>
            {
                type === ModalType.Prompt ? <PromptModal message={message} is={is} setIs={setIs}></PromptModal> :
                    <InquireModal identifyLable={identifyLable} identifyCallBack={identifyCallBack}
                        cancelLable={cancelLable} cancelCallBack={cancelCallBack} message={message} is={is}
                        setIs={setIs}
                    ></InquireModal>
            }
        </>
    )
}

//提示-----------------------------------------------
interface PromptModalProps {
    //信息
    message: string,
    //是否显示弹框
    is: boolean,
    setIs: Dispatch<SetStateAction<boolean>>
}
const PromptModal: React.FC<PromptModalProps> = ({ message, is, setIs }) => {
    return (
        <>
            <IcpDialog is={is}>
                <div className={style.prompt}>
                    <div className={style.head}>提示</div>
                    <IcpHr></IcpHr>
                    <div className={style.msg}>{message}</div>
                    <div className={style.identify} onClick={() => {
                        setIs(false)
                    }}>确定</div>
                </div>
            </IcpDialog>
        </>
    )
}
//询问-----------------------------------------------
interface InquireModalProps {
    //确定按钮文本 询问类型有效
    identifyLable?: string,
    //确定按钮点击回调 询问类型有效
    identifyCallBack?: () => void,
    //取消按钮文本 询问类型有效
    cancelLable?: string,
    //取消按钮点击回调
    cancelCallBack?: () => void,
    //信息
    message: string,
    //是否显示弹框
    is: boolean,
    setIs: Dispatch<SetStateAction<boolean>>
}
const InquireModal: React.FC<InquireModalProps> = ({ identifyLable, identifyCallBack, cancelLable, cancelCallBack, message, is, setIs }) => {
    return (
        <>
            <IcpDialog is={is}>
                <div className={style.inquire}>
                    <div className={style.msg}>
                        {message}
                    </div>
                    <div className={style.btnlist}>
                        <div
                        className={style.cancel}
                            onClick={() => {
                                setIs(false);
                                if (cancelCallBack) {
                                    cancelCallBack();
                                }
                            }}
                        >{cancelLable}</div>
                        <div
                            className={style.identify}
                            onClick={() => {
                                setIs(false)
                                if (identifyCallBack) {
                                    identifyCallBack()
                                }
                            }}
                        >{identifyLable}</div>

                    </div>
                </div>
            </IcpDialog>
        </>
    )
}
export default IcpModal;
