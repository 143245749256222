import React, { useRef, useEffect } from "react";
import * as echarts from 'echarts';
interface IcpeEhartsProps{
    //标题
    title:string,
    //时间
    date:Array<string>
    //值
    value:Array<number>
}

const IcpeEharts:React.FC <IcpeEhartsProps>= ({title,date,value}) =>{
    const container = useRef(null);
    useEffect(() => {
        if (!container.current) {
            return;
        }
        const chart = echarts.init(container.current as HTMLDivElement);
        const option: echarts.EChartOption = {
            grid: {
                top: '40px',     // 清楚上边距
                bottom: '40px',  // 清楚下边距
                left: '50px',    // 清楚左边距
                right: '20px'    // 清楚右边距
              },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: date,
                axisTick: {
                    show:false
                },
                axisLabel:{
                    color: '#8B9EC0' 
                },
                axisLine: {
                    show:false
                }
            },
            yAxis: {
                type: 'value',
                splitLine: {
                    lineStyle: {
                        type: 'dashed', 
                        color:'#303754'
                    }
                },
                axisLabel:{
                    color: '#8B9EC0' 
                }
            },
            tooltip: {
                axisPointer: {
                    type: 'none',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            series: [
                {
                    type: 'line',
                    showSymbol: true,
                    name: title,
                    data: value,
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(7,174,143,0.3)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(7,174,143,0.01)'
                            }
                        ])
                    },
                    emphasis: {
                        itemStyle: {
                            borderWidth: 10 // 鼠标悬停时增加数据点大小
                        }
                    },
                    lineStyle: {

                        color: '#07AE8F'
                    },
                    itemStyle: {
                        color: '#07AE8F'

                    }
                }
            ]
        }

        chart.setOption(option);
        const resizeChart = () => {
            if (chart) {
              chart.resize();  // 调整图表尺寸
            }
          }
      
          // 添加 resize 事件监听器
          window.addEventListener('resize', resizeChart);
      
          // 清除监听器
          return function cleanup() {
            window.removeEventListener('resize', resizeChart);
          };
    }, [container]);
    return (
        <>
          <div ref={container} style={{ width: '100%', height: '18.75rem' }}></div>
        </>
    )
}
export default IcpeEharts;