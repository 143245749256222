import React, { useEffect, useState } from "react";
import style from './index.module.less';
export enum BtnType {
    //收益
    Income = 'Income',
    //质押
    Pledges = 'Pledges',
    //邀请
    Attend = 'Attend',
    //vip
    Vip = 'Vip',
    //抵押
    Collateral = 'Collateral'
}
interface IcpButtonProps {
    //按钮类型
    type: BtnType,
    //回调函数
    onClick?: () => void,
    //是否禁用
    disable?: boolean,
    //样式
    className?: string,
    children: React.ReactNode,

}

const IcpButton: React.FC<IcpButtonProps> = ({ type, onClick, disable, className, children }) => {
    const [styleName, setStyleName] = useState<string>('');
    useEffect(() => {
        let name: string = '';
        if (disable) {
            name = style.disable
        } else {
            switch (type) {
                case BtnType.Income:
                    name = style.income;
                    break;
                case BtnType.Pledges:
                    name = style.pledges;
                    break;
                case BtnType.Vip:
                    name = style.vip;
                    break;
                case BtnType.Collateral:
                    name = style.collateral;
                    break;
                case BtnType.Attend:
                    name = style.attend;
                    break;
                default:
                    break;
            }
        }
        setStyleName(`${style.button} ${className} ${name}`);
    }, [disable,className])
    return (
        <>
            {className !== '' ? <button className={styleName} onClick={onClick} disabled={disable}>{children}</button>
                : null
            }
        </>
    )
}
IcpButton.defaultProps = {
    disable: false
}
export default IcpButton;