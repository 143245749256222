import { create } from 'zustand';
import { UserInfoT } from '@/api/user/types/user';
import { Overview } from '@/api/assest/types/assest';
// 定义状态和 actions 的接口
interface Store {
    userInfo: UserInfoT | null;
    overview: Overview | null,
    setUserInfo: any;
    setOverview: any;

}
const useStore = create<Store>(set => ({
    //用户信息
    userInfo: null,
    //总资产
    overview: null,
    setUserInfo: (value: UserInfoT) => set(() => ({
        userInfo: value
    })),
    setOverview: (value: Overview) => set(() => ({
        overview: value
    })),
}))
export default useStore;

