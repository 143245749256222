import React, { useEffect, useState } from "react";
import style from './index.module.less';
import UserInfo from "../userInfo";
import Knots from "../knots";
import { Pledge, Level, Overview } from '@/api/assest/types/assest';
import IcpButton, { BtnType } from "@/components/icp-button";
import { pledge_levels, pledge_upgrade, asset_overview ,asset_unpledge } from '@/api/assest/index';
import useStore from "@/store/store";
import { apiResponse } from "@/api/apiType";
import IcpModal, { ModalType } from "@/components/icp-modal";

interface alertType {
    type: ModalType,
    //确定按钮文本 询问类型有效
    identifyLable?: string,
    //确定按钮点击回调 询问类型有效
    identifyCallBack?: () => void,
    //取消按钮文本 询问类型有效
    cancelLable?: string,
    //取消按钮点击回调
    cancelCallBack?: () => void,
    //信息
    message: string,
}
const Pledges: React.FC = () => {
    const setoverview = useStore(state => state.setOverview);
    const [thisLevel, setThisLevel] = useState<number>(0);//当前等级索引
    const [node, setNode] = useState<Array<Pledge>>();//等级列表
    const overview = useStore(state => state.overview);
    const [thisIcp, setThisIcp] = useState<string>('0');
    const [isAlert, setIsAlert] = useState<boolean>(false);
    const [alert, setAlert] = useState<alertType>();
    useEffect(() => {
        if (node) {
            if (node.length > thisLevel) {
                setThisIcp(node[thisLevel].tokenAmount)
            } else {
                setThisIcp('0')
            }
        }
    }, [thisLevel, node])
    useEffect(() => {
        //默认选中当前等级的下一级
        if (overview && overview.powerLevel) {
            setThisLevel(overview.powerLevel)
        } else {
            setThisLevel(1)
        }
        if (!node) {
            pledge_levels().then((res: apiResponse<Pledge[]>) => {
                if (res.code === 2000) {
                    setNode(res.data)
                }
            })
        }
    }, [overview])
    /**
     * 质押升级
     */
    const upgrade = () => {
        //判断当余额
        if (overview && node && Number(overview.asset.usable) > Number(thisIcp)) {
            let ple: Pledge = node[thisLevel];
            pledge_upgrade(ple.level).then((res: apiResponse<Level>) => {
                if (res.code === 2000) {
                    //刷新资产
                    updateAsset();
                    //质押成功
                    setAlert({
                        type: ModalType.Prompt,
                        message: '升级成功！'
                    })
                    setIsAlert(true)
                } else {
                    setAlert({
                        type: ModalType.Prompt,
                        message: res.msg
                    })
                    setIsAlert(true)
                }
            })
        } else {
            setAlert({
                type: ModalType.Prompt,
                message: "卡时不足，质押失败"
            })
            setIsAlert(true)
        }
    }
    /**
     * 刷新资产
     */
    const updateAsset = () => {
        //获取资产
        asset_overview().then((res: apiResponse<Overview>) => {
            if (res.code === 2000) {
                setoverview(res.data);
            }
        })
    }

    /**
     * 解除质押
     */
    const unbundle = () =>{
        setAlert({
            type:ModalType.Inquire,
            message:'解押后今日将无法获得星际算力节点各项权益，是否解押？',
            identifyLable:'全部解压',
            cancelLable:'再想想',
            identifyCallBack:()=>{
                asset_unpledge().then((res: apiResponse<Level>)=>{
                    if(res.code===2000){
                        //刷新资产
                        updateAsset();
                        setAlert({
                            type: ModalType.Prompt,
                            message: '解压成功！'
                        })
                        setIsAlert(true)
                    }else{
                        setAlert({
                            type: ModalType.Prompt,
                            message: res.msg
                        })
                        setIsAlert(true)
                    }
                })

            }
        })
        setIsAlert(true)
    }

    return (
        <>
            {alert && <IcpModal type={alert.type} message={alert.message} is={isAlert} setIs={setIsAlert}
                cancelLable={alert.cancelLable} identifyLable={alert.identifyLable} identifyCallBack={alert.identifyCallBack}
            ></IcpModal>}

            <div className={style.container}>
                <div className={style.userinfo}>
                    <UserInfo onUnbundle={unbundle}></UserInfo>
                </div>
                <div className={style.nodal}>
                    {
                        node && node.map((item, index) => (
                            <Knots level={item.level} tokenAmount={item.tokenAmount}
                                action={thisLevel === index}
                                disable={overview && index < overview.powerLevel ? true : false}
                                onClick={() => { setThisLevel(index) }}
                                key={index}
                            ></Knots>
                        ))
                    }
                </div>
                <div className={style.title}>星际算力节点权益</div>
                <img className={style.rules} src="https://card-bag.oss-cn-beijing.aliyuncs.com/card-bag/20240125/FeKDHbAOvYdtOG3h.png" alt="太一灵境" />
                <div className={style.rules_info}>
                    <div className={style.rules_title}>质押规则</div>
                    <div className={style.rules_list}>
                        <p>1、质押卡时，获多项权益，随时可解押；</p>
                        <p>2、需满足 ≥24小时且在0点结算时处于质押状态；</p>
                        <p>3、解除质押将恢复至1级，当日收益将无法获得星际算力节点权益。</p>
                        <p>4、邀请新人奖励：成功邀请好友注册，会获得1次性奖励额度（不同星际算力等级，额度不同；需对方完成10卡时收益后获得）</p>
                        <p>5、已邀请好友每日分润：包含所有受邀请好友的在线时长基础奖励总和*当前分润比例（不同星际算力等级，分润比例不同）</p>
                    </div>
                </div>
                <div className={style.pay}>
                    <div className={style.content}>
                        <div>
                            <div className={style.monetary}>{thisIcp}<span className={style.uni}>卡时</span></div>
                            <div className={style.balance}>{overview?.asset.usable ?? 0}卡时可用</div>
                        </div>
                        <IcpButton type={BtnType.Pledges} className={style.btn_play}
                            disable={node && node.length <= thisLevel ? true : false}
                            onClick={() => {
                                upgrade()
                            }}
                        >立即质押</IcpButton>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pledges;