import axios, { type AxiosInstance, type AxiosRequestConfig } from "axios"
const API_URL = process.env.REACT_APP_BASE_API // 这里设置你的API URL地址
/** 创建请求实例 */
function createService() {
  // 创建一个 axios 实例命名为 service
  const service = axios.create({
    baseURL: API_URL,
    timeout: 10000
  })
  // 请求拦截
  service.interceptors.request.use(
    (config) => {
      config.headers['Client-Env'] = sessionStorage.getItem('env');
      config.headers['token'] =  sessionStorage.getItem('token');
      return config;
    },
    // 发送失败
    (error) => Promise.reject(error)
  )
  // 响应拦截（可根据具体业务作出相应的调整）
  service.interceptors.response.use(
    (response) => {

      return response.data;
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  return service
}

/** 创建请求方法 */
function createRequest(service: AxiosInstance) {
  return function <T>(config: AxiosRequestConfig): Promise<T> {
    return service(config)
  }
}
/** 用于网络请求的实例 */
const service = createService()
/** 用于网络请求的方法 */
export const request = createRequest(service)