import React, { useEffect } from "react";
import style from './index.module.less';
interface IcpDialogProps {
    //是否显示
    is?: boolean,
    children?: React.ReactNode
}

const IcpDialog: React.FC<IcpDialogProps> = ({ is, children }) => {
    useEffect(()=>{
        if(is){
            document.body.style.overflow = 'hidden';
        }else{
            document.body.style.overflow = 'auto';
        }
    },[is])
    return (
        <>
            {
                is && <div className={style.container}>
                    <div className={style.body}>
                        {children}
                    </div>
                </div>
            }
        </>
    )
}

IcpDialog.defaultProps = {
    is: false
}
export default IcpDialog;