import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { setupRem } from './utils/rem';
setupRem()
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
console.log("root.render")
root.render(
    <App />
);

// <React.StrictMode>
