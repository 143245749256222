import React, { useEffect, useState } from "react";
import style from './index.module.less';
import IcpButton, { BtnType } from "@/components/icp-button";
import useStore from "@/store/store";
import { UserInfoT } from '@/api/user/types/user';
//定义props
interface UserInfoProps {
    onClick?: () => void
  
}
//用户信息
const UserInfo: React.FC<UserInfoProps> = ({ onClick }) => {
    const user = useStore(state => state.userInfo);
    const [vip, setVip] = useState<string>('')
    const overview = useStore(state => state.overview);
    useEffect(() => {
        if (user !== null) {
            //会员等级
            switch (user.level.vipLevel) {
                case 1:
                    //vip是否有效
                    if (user.level.levelStatus ===1) { 
                        setVip('https://card-bag.oss-cn-beijing.aliyuncs.com/card-bag/20240123/92mVncnLT9Mkyu1F.svg')
                    } else {
                        setVip('https://card-bag.oss-cn-beijing.aliyuncs.com/card-bag/20240127/0YL3nyzG4Wb1kO2N.png');
                    }
                    break;
                default:
                    //默认0级别
                    setVip('https://card-bag.oss-cn-beijing.aliyuncs.com/card-bag/20240126/h8j3sCIh6v0ZhZQB.png');
                    break;
            }
        }
    }, [user])

    const getAvatar = (user:UserInfoT|null) :string => {
        if(user){
            if(user.icon && user.icon!==''){
                return user.icon
            }else{
                return 'https://card-bag.oss-cn-beijing.aliyuncs.com/card-bag/20240127/JLBzMivFzEhdejOQ.png'
            }
        }else{
            return 'https://card-bag.oss-cn-beijing.aliyuncs.com/card-bag/20240127/JLBzMivFzEhdejOQ.png'
        }
    }
    const getName = (user:UserInfoT|null) :string =>{
        if(user){
            if(user.nickname!==''){
                return user.nickname;
            }else{
                return `tai${user.id}`;
            }
        }else{
            return '';
        }
    }
    return (
        <>
            <div className={style.user}>
                <div className={style.info}>
                    <img className={style.avatar} src={getAvatar(user)} alt="太一灵境" draggable="false" />
                    <div className={style.content}>
                        <div className={style.nickname}>{getName(user)}</div>
                        <div className={style.tabs}>
                            <img src={vip} className={style.vip_ico} alt="太一灵境" />
                            <div className={style.nodal}>星际算力节点 {overview ?.powerLevel ??''}</div>
                        </div>
                    </div>
                </div>
                <IcpButton type={BtnType.Income} onClick={onClick}>收益明细</IcpButton>
            </div>
        </>
    )
}
export default UserInfo;