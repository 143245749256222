import {request} from "@/utils/request";
import { apiRequest, apiResponseList, apiResponse } from '../apiType';
import { Income, Overview, Level ,Pledge ,Invite ,Shareprofit} from './types/assest';
/**
 * 收益明细
 * @param params 
 * @returns 
 */
export const assest_details = (params: apiRequest) => {
    return request<apiResponseList<Income>>({
        url: '/user/asset/details',
        method: 'GET',
        params
    })
}

/**
 * 资产总览
 */
export const asset_overview = () => {
    return request<apiResponse<Overview>>({
        url: '/user/asset/overview',
        method: 'GET'
    })
}

/**
 * 质押等级列表
 */
export const pledge_levels = () => {
    return request<apiResponse<Pledge[]>>({
        url: '/user/asset/pledge/levels',
        method: 'GET'
    })
}

/**
 * 质押升级
 */
export const pledge_upgrade = (level: number) =>{
    return request<apiResponse<Level>>({
        url: '/user/asset/pledge/upgrade',
        method: 'GET',
        params: { level }
    })
}

/**
 * 总收益
 */
export const asset_sum = () =>{
    return request<apiResponse<string>>({
        url: '/user/asset/sum',
        method: 'GET'
    })
}

/**
 * 解除质押
 */
export const asset_unpledge = () =>{
    return request<apiResponse<Level>>({
        url: '/user/asset/unpledge',
        method: 'GET'
    })
}
/**
 * 邀请收益总览
 */
export const invite_overview =() =>{
    return request<apiResponse<Invite>>({
        url: '/user/asset/invite/overview',
        method: 'GET'
    })
}

/**
 * 邀请分润收益列表(持续性奖励)
 */
export const invite_shareprofit = (params: apiRequest) =>{
    return request<apiResponseList<Shareprofit>>({
        url: '/user/asset/invite/shareprofit',
        method: 'GET',
        params
    })
}