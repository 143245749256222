import React, { useEffect, useState } from "react";
import { Pledge } from '@/api/assest/types/assest';
import style from './index.module.less';
//props
interface KnotsProps extends Pledge {
    //是否选中
    action?: boolean,
    //是否禁用
    disable?: boolean,
    onClick: () => void
}
const Knots: React.FC<KnotsProps> = ({ level, tokenAmount, action, disable, onClick }) => {
    const [className,setClassName] = useState<string>('');
    useEffect(()=>{
        if(disable){
            setClassName(style.disable)
        }else{
            if(action){
                setClassName(style.action)
            }else{
                setClassName(style.container)
            }
        }
    },[action,disable])
    return (
        <>
            <div className={className} onClick={() => {
                if (!disable) {
                    onClick()
                }
            }}>
                <div className={style.level}>节点等级{level}</div>
                <div className={style.token}>{tokenAmount}</div>
                <div className={style.lable}>质押（卡时）</div>
            </div>
        </>
    )
}
Knots.defaultProps = {
    action: false,
    disable: false
}
export default Knots;