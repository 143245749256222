import React, { Dispatch, SetStateAction, useEffect } from "react";
import style from './index.module.less';
interface IcpToastProps {
    message: string,
    is: boolean,
    setIs: Dispatch<SetStateAction<boolean>>
}
const IcpToast: React.FC<IcpToastProps> = ({ message, is, setIs }) => {
    useEffect(()=>{
        //如果显示默认 5秒后自动隐藏
        if(is){
            setTimeout(()=>{
                setIs(false);
            },1000)
        }
    },[is])
    return (
        <>
            {is &&
                <div className={style.container}>
                    <div className={style.message}>{message}</div>
                </div>
            }
        </>
    )
}

export default IcpToast;