import React from "react";
import style from './index.module.less';
interface IcpNullProps {
    message: string
}
const IcpNull: React.FC<IcpNullProps> = ({ message }) => {
    return (
        <>
            <div className={style.container}>
                <div>
                    <img src="https://card-bag.oss-cn-beijing.aliyuncs.com/card-bag/20240127/hYrqzl4Pbeg6CWRN.svg" alt="太一灵境" draggable="false"/>
                    <div className={style.msg}>{message}</div>
                </div>
            </div>
        </>
    )
}

export default IcpNull;