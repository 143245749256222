import React, { Dispatch, SetStateAction } from "react";
import style from './index.module.less'
interface IcpTabsProps {
    //选项的数组
    options: Array<string>,
    //选中的索引
    action?: number,
    setAction: Dispatch<SetStateAction<number>>
}
const IcpTabs: React.FC<IcpTabsProps> = ({ options, action, setAction }) => {
    return (
        <>
            <div className={style.container}>
                {
                    options.map((item, index) => (
                        <div key={index} className={action === index ? style.action : style.options}
                            onClick={() => setAction(index)}
                        >{item}</div>
                    ))
                }
            </div>
        </>
    )
}

IcpTabs.defaultProps = {
    action: 0
}
export default IcpTabs;