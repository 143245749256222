import { createBrowserRouter } from "react-router-dom";
import HomePage from '@/pages/home/index';
import UpgradePage from "@/pages/upgrade";
import Billing  from "@/pages/billing";
const router = createBrowserRouter([
    {
        path:"/",
        Component:HomePage
    },
    {
        path:"/upgrade",
        Component:UpgradePage
    },{
        path:'/billing',
        Component:Billing
    }
]);

export default router;