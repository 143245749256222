import React, { useEffect, useState } from "react";
import style from './index.module.less';
import IcpTabs from "@/components/icp-tabs";
import Invitation from "./components/invitation";
import Pledges from "./components/pledges";
import IcpShow from "@/components/icp-show";
import { useLocation } from "react-router-dom";
const UpgradePage: React.FC = () => {
    const [action, setAction] = useState<number>(0);
    const location = useLocation();  
    useEffect(()=>{
        setAction(location.state.type)
    },[location])
    return (
        <>
            <div className={style.root}>
                <IcpTabs options={['质押', '邀请好友']} action={action} setAction={setAction}></IcpTabs>
                <IcpShow is={action === 0}><Pledges></Pledges></IcpShow>
                <IcpShow is={action === 1}><Invitation></Invitation></IcpShow>
            </div>
        </>
    )
}

export default UpgradePage;