import {request} from "@/utils/request";
import { apiResponse } from '../apiType';
import { UserInfoT ,UserCode} from "./types/user";
/**
 * 用户信息
 */
export const user_info = () => {
    return request<apiResponse<UserInfoT>>({
        url: '/user/info',
        method: 'GET'
    })
}
/**
 * 邀请码
 */

export const invite_code =(generate:number) =>{
    return request<apiResponse<UserCode>>({
        url: '/invite/code',
        method: 'GET',
        params:{
            generate:generate
        }
    })
}