import React, { useEffect, useState } from "react";
import style from './index.module.less';
import Signage from "../signage";
import Seniority from "../seniority";
import { invite_code } from '@/api/user/index';
import { apiResponse } from '@/api/apiType';
import { UserCode } from "@/api/user/types/user";
import IcpToast from "@/components/icp-toast";
const Invitation: React.FC = () => {
    const [isCopy , setIsCopy] = useState<boolean>(false);
    const [code, setCode] = useState<string>();
    useEffect(() => {
        invite_code(0).then((res: apiResponse<UserCode>) => {
            if (res.code === 2000) {
                setCode(res.data.inviteCode)
            }
        })
    }, [])
    /**
     * 复制
     */
    const onCopy = () => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(`1. taiyi.cn下载太一灵境客户端 2. 注册并填写邀请码：${code}`)
                .then(() => {
                    setIsCopy(true);
                })
                .catch(err => {
                    copyDocument(`1. taiyi.cn下载太一灵境客户端 2. 注册并填写邀请码：${code}`)
                });
        } else {
            copyDocument(`1. taiyi.cn下载太一灵境客户端 2. 注册并填写邀请码：${code}`)
        }
    }
    /**
     * 降低适配
     */
    const copyDocument = (text: string) => {
        let dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        // 选择textarea元素的内容。
        let successful = document.execCommand("copy");
        if (successful) {
            document.body.removeChild(dummy);
            //复制成功
            setIsCopy(true);
        } else {
            document.body.removeChild(dummy);
            //复制失败
        }

    }
    return (
        <>
            <IcpToast message={"复制成功"} is={isCopy} setIs={setIsCopy}></IcpToast>
            <div className={style.container}>
                <div className={style.sum}>
                    <Signage></Signage>
                </div>
                {code &&
                    <div className={style.share}>
                        <div className={style.title}>立即邀请</div>
                        <div className={`${style.share_lable} ${style.lable1}`}>1. taiyi.cn下载太一灵境客户端 </div>
                        <div className={`${style.share_lable} ${style.lable2}`}>2. 注册并填写邀请码：{code}</div>
                        <div className={style.copy} onClick={onCopy}>
                            <img src="https://card-bag.oss-cn-beijing.aliyuncs.com/card-bag/20240125/LLo0mfaSTmn4lBXD.svg" alt="复制" />复制邀请信息
                        </div>
                    </div>
                }
                <div className={style.rewards}>
                    持续性奖励
                </div>
                <Seniority></Seniority>
            </div>
        </>
    )
}
export default Invitation;