import React from "react";
//props
interface IcpShowProps {
    //是否显示
    is: boolean,
    //样式
    className?: string,
    children: React.ReactNode,
}

const IcpShow: React.FC<IcpShowProps> = ({ is, className, children }) => {
    return (
        <div style={!is ? { display: 'none' } : {}} className={className}>
            {children}
        </div>
    )
}

export default IcpShow;