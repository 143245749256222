import React, { useEffect, useState } from 'react';

import style from './index.module.less';
import IcpeEharts from '@/components/icp-echarts';
import { DayIncome } from '@/api/assest/types/assest';
import {formatDate} from '@/utils/index';
interface StatementProps {
    list: Array<DayIncome> | []
}
const Statement: React.FC<StatementProps> = ({ list }) => {
    const [assetDate,setAssetDate] = useState<Array<string>>();
    const [tokenAmount,seTokenAmount] = useState<Array<number>>();
    useEffect(() => {
        if (list.length > 0) {
            let date:Array<string> = [];
            let amount:Array<number> = [];
            list.forEach((item:DayIncome)=>{
                date.push(
                    formatDate(item.assetDate)
                )
                amount.push(Number(item.tokenAmount))
            })
            date[6] = '昨天';
            setAssetDate(date);
            seTokenAmount(amount);
           

        }
    }, [list])
    return (
        <>
            <div className={style.title}>近7日收益</div>
            {
                assetDate && tokenAmount &&(<IcpeEharts title={'卡时'} date={ assetDate } value={tokenAmount}></IcpeEharts>)
            }
        </>
    )
}

export default Statement;